<!--
 * @Description: 详细参数
 * @Author: zhang zhen
 * @Date: 2023-02-16 17:27:32
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-02-16 17:59:13
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/detailedParameter.vue
-->
<template>
  <div class="detailedParameter">
    <div class="detailedParameter-title">详细参数</div>
    <a-tabs v-model="activeTab">
      <a-tab-pane key="1" tab="纸制品">
        <a-form-model
          v-bind="{
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          }"
          style="width:300px;padding-left: 27px;"
        >
          <a-form-model-item label="盒型:" labelAlign="left" required>
            <a-select placeholder="请选择" v-model="form.kind">
              <a-select-option value="1">平口箱</a-select-option>
              <a-select-option value="2">撕拉箱</a-select-option>
              <a-select-option value="3">飞机盒</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="品牌:" labelAlign="left" required>
            <a-select placeholder="请选择" v-model="form.b">
              <a-select-option value="1">选择一</a-select-option>
              <a-select-option value="2">选择二</a-select-option>
              <a-select-option value="3">选项三</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="克重:" labelAlign="left" required>
            <a-select placeholder="请选择" v-model="form.c">
              <a-select-option value="1">选择一</a-select-option>
              <a-select-option value="2">选择二</a-select-option>
              <a-select-option value="3">选项三</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="级别:" labelAlign="left" required>
            <a-select placeholder="请选择" v-model="form.d">
              <a-select-option value="1">选择一</a-select-option>
              <a-select-option value="2">选择二</a-select-option>
              <a-select-option value="3">选项三</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="瓦楞层数:" labelAlign="left" required>
            <a-select placeholder="请选择" v-model="form.f">
              <a-select-option value="1">选择一</a-select-option>
              <a-select-option value="2">选择二</a-select-option>
              <a-select-option value="3">选项三</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="瓦楞楞型:" labelAlign="left" required>
            <a-select placeholder="请选择" v-model="form.g">
              <a-select-option value="1">选择一</a-select-option>
              <a-select-option value="2">选择二</a-select-option>
              <a-select-option value="3">选项三</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="水印:" labelAlign="left" required>
            <a-select placeholder="请选择" v-model="form.h">
              <a-select-option value="1">选择一</a-select-option>
              <a-select-option value="2">选择二</a-select-option>
              <a-select-option value="3">选项三</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="模切:" labelAlign="left" required>
            <a-select placeholder="请选择" v-model="form.i">
              <a-select-option value="1">选择一</a-select-option>
              <a-select-option value="2">选择二</a-select-option>
              <a-select-option value="3">选项三</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="制造尺寸:" labelAlign="left" required>
            <a-input placeholder="请输入制造尺寸" v-model="form.j"></a-input>
          </a-form-model-item>
          <a-form-model-item label="展开尺寸:" labelAlign="left" required>
            <a-input placeholder="请输入制造尺寸" v-model="form.k"></a-input>
          </a-form-model-item>
          <a-form-model-item label="波浪胶:" labelAlign="left" required>
            <a-checkbox>
              贴波浪胶
            </a-checkbox>
          </a-form-model-item>
          <a-form-model-item label="糊箱:" labelAlign="left" required>
            <a-checkbox>
              糊箱
            </a-checkbox>
          </a-form-model-item>
          <a-form-model-item label="管理费:" labelAlign="left" required>
            <a-checkbox>
              管理费
            </a-checkbox>
          </a-form-model-item>
          <a-form-model-item label="瓦楞层数:" labelAlign="left" required>
            <a-upload-dragger name="file" :multiple="true">
              <p class="ant-upload-drag-icon">
                <a-icon type="cloud-upload" />
              </p>
              <p class="ant-upload-text">
                将文件拖拽到此处
              </p>
              <p class="ant-upload-hint">
                或点击上传
              </p>
            </a-upload-dragger>
          </a-form-model-item>
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane key="2" tab="木制品"></a-tab-pane>
      <a-tab-pane key="3" tab="塑料"></a-tab-pane>
    </a-tabs>
    <div class="options">
      <a-button type="primary">提交方案</a-button>
      <a-button>取消</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailedParameter',
  data() {
    return {
      activeTab: '1',
      form: {}
    }
  }
}
</script>

<style lang="less" scoped>
.detailedParameter {
  background: #fffff8;
  padding: 15px 20px;
  border-radius: 8px;
  &-title {
    color: #090b12;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .options {
    text-align: center;
    margin-top: 100px;
    .ant-btn {
      &.ant-btn {
        margin-left: 30px;
      }
    }
  }
}
</style>
